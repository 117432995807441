import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const Page = ({ className, title, description, children }) => {
  const css = classNames({
    'sm:px-16': !className,
    [className]: className,
  });

  return (
    <section className={css}>
      <h1 className="text-center pb-8 text-4xl" data-testid="page-title">
        {title}
      </h1>
      {description && (
        <p
          className="text-center pb-8 text-xl max-w-3xl mx-auto"
          dangerouslySetInnerHTML={{ __html: description }}
          data-testid="page-description"
        />
      )}
      {children}
    </section>
  );
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any.isRequired,
};
